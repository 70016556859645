import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import banner from '../../assets/images/mamas-baner.jpg'
import { SuccessMessage } from '../../helper/NotificationHelper';
import { BioUpdate } from '../../API/APIBio';
import thumb from '../../assets/images/user.jpg'

const BiooUpdate = ({Details, }) => {

    const [username, Setusername] = useState()
    const [Name, setName] = useState()
    const [MainTitle, setMainTitle] = useState()

    useEffect(() => {

        setName(Details?.DisplayName)
        setMainTitle(Details?.MainTitle)

    }, [Details])

    const SaveData = async () => {
        const username = Details['UserName']
        const PostBody = {DisplayName: Name, MainTitle: MainTitle}
        const save = await BioUpdate(username, PostBody)
        if(save === true){
            SuccessMessage('Changes saved success')
        }
    }

    return (
        <Fragment>
                <div style={{background: '#e3e3e3'}}>
                <div className='container'>
                    <div className="row">
                        <div className="col-12 forM-btn" style={{position: 'relative'}}>
                            <button onClick={SaveData} className='set-bio-photo-btn mbtn tr2' style={{fontSize: '13px !important'}}>Save changes</button>

                            <div className='mamas-bio-details center-midle' style={{background: '#e6f1fd', borderRadius: '12px', borderColor: '#52288491'}}>
                                
                                <div>
                                    <img src={Details?.['ProfileLImg'] === "" ? thumb : Details?.['ProfileLImg']} alt="bio" style={{borderColor: '#52288491'}}/>
                                </div>

                                <div>
                                    <input onChange={(e)=> setName(e.target.value)} type="text" disabled defaultValue={Name} />
                                </div>

                                <div>
                                    <textarea onChange={(e)=> setMainTitle(e.target.value)} defaultValue={MainTitle}></textarea>
                                </div>

                                <div className='bios-socials'>
                                    {
                                        Details?.['SocialLinks']?.['WhatsApp']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['WhatsApp']?.['link']}><i class="bi bi-whatsapp"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Telegram']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Telegram']?.['link']}><i class="bi bi-telegram"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Linkedin']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Linkedin']?.['link']}><i class="bi bi-linkedin"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Facebook']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Facebook']?.['link']}><i class="bi bi-facebook"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Instagram']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Instagram']?.['link']}><i class="bi bi-instagram"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                </div>

                                <div className='mamas-bios-buttons center-midle'>


                                    {
                                        Details?.['Buttons']?.[0]?.['link'] ? (
                                            <Link to={Details?.['Buttons']?.[0]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>{Details?.['Buttons']?.[0]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['Buttons']?.[1]?.['link'] ? (
                                            <Link to={Details?.['Buttons']?.[1]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>{Details?.['Buttons']?.[1]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['Buttons']?.[2]?.['link'] ? (
                                            <Link to={Details?.['Buttons']?.[2]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>{Details?.['Buttons']?.[2]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['Buttons']?.[3]?.['link'] ? (
                                            <Link to={Details?.['Buttons']?.[3]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>{Details?.['Buttons']?.[3]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    
                                    {
                                        Details?.['Buttons']?.[4]?.['link'] ? (
                                            <Link to={Details?.['Buttons']?.[4]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>{Details?.['Buttons']?.[4]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </Fragment>
    );
};

export default BiooUpdate;