import React, { Fragment } from 'react';

const BioCreateButton = () => {

    const StartBio = () => {
        alert('hello')
    }

    return (
        <Fragment>
            <div className='bio-tools-one center-midle'>
                <span onClick={StartBio} className='tr3'><i class="bi bi-plus"></i></span>
                <h2 className='title1 tr3'>Start bio</h2>
                <p className='text-center text tr3'>Boost your sales with MAMAS' innovative tools. Leverage them effectively to elevate your brand on a global scale</p>
            </div>
        </Fragment>
    );
};

export default BioCreateButton;