import React, { Fragment, Suspense, useEffect, useState } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import BioWidget from '../../components/bios/BioWidget';
import BioUpdate from '../../components/bios/BioUpdate';
import { BioDetails } from '../../API/APIBio';
import { useSelector } from 'react-redux';

const UpdateBioPage = () => {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const username = params.get('user');
        (async() => {
            await BioDetails(username)
        })()
    }, [])

    const Details = useSelector((state) => (state.bio.BioDetail))



    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12">
                                <BioUpdate Details={Details} />
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <BioWidget Details={Details}/>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default UpdateBioPage;