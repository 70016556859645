import { createSlice } from "@reduxjs/toolkit";

export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState: {
        Details: {},
        SubList: []
    },
    reducers: {
        setDetails: (state, action) => {
            state.Details = action.payload;
        },
        setSubList: (state, action) => {
            state.SubList = action.payload;
        }
    }
})

export const  { setSubList, setDetails } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;