import React, { useEffect, useRef, useState } from 'react';
import { IsEmpty } from '../../helper/FormHelper';
import { RequiredMessage, SuccessMessage } from '../../helper/NotificationHelper';
import { MatchCupuon } from '../../API/APIOrder';


const CuopnForm = ({ onClick }) => {

    let cupuonRef = useRef()
    const [PackID, setPackID] = useState()
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const ID = params.get('id');
        (async() => {
            setPackID(ID)
        })()
    }, [])

    const onFormSubmit = (event) => {
        event.preventDefault()
    }

    const ReducePrice =async () => {
        const cupuon = cupuonRef.value;
        const PostBody = {Cupon: cupuon}
        if(IsEmpty(cupuon)){
            RequiredMessage('Place MAMAS discount code')
        }else{
            const match = await MatchCupuon(PackID, PostBody)
            if(match === 'invalid'){
                RequiredMessage('Invalid cupuon code')
            }else if(match?.data?.message === 'Congrats you got discount price'){
                window.location.href = `/purchase?price=${match?.data?.price?.DiscountPrice}`
            }else{
                RequiredMessage('Try again')
            }
        }
    }


    return (
        <div className={`cupuon-form`}>
            
            <form onSubmit={onFormSubmit} className='center-midle'>
                <span onClick={onClick} className='close tr2'><i class="bi bi-x-lg tr2"></i></span>
                <h2 className='title1' style={{textAlign: 'center'}}>MAMAS Discount Code</h2>
                <div className=''>
                    <label htmlFor="cupupon" className='text' style={{color: 'var(--color2)'}}>Place your special cupuon code and <strong>Reduce Price</strong></label>
                    <input ref={(input)=> cupuonRef=input} className='form-control' type="text" placeholder='e.g 8S72XXX24' />
                </div>
                <button onClick={ReducePrice} className='btn login-regist mt-4' style={{width: '50%', color: 'var(--color)'}}>Reduce price</button>
            </form>
        </div>
    );
};

export default CuopnForm;