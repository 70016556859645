import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const DashbaordWidget = () => {
    return (
        <Fragment>
            <div>
                <div className='mamas-widget-area parentTR'>
                    <h2>Direct support</h2>
                    <div className="suppor-social d-flex justify-content-between pt-2">
                        <a href="#">WHATSAPP<i class="bi bi-whatsapp"></i></a>
                        <a href="#">FACEBOOK<i class="bi bi-facebook"></i></a>
                    </div>
                </div>

                <div className='mamas-widget-area parentTR mt-3'>
                    <h2>MAMAS Community Standards</h2>

                    <p>If you violate the guidelines of mamas in any way, your account may be restricted. Mamas' slogan is "Stand by us with integrity, we are by your side."</p>
                        
                    <h4>Analytics</h4>
                    <ul>
                        <li className='analytics-list'>
                            <Link className='analytics-list-anker tr2'>username</Link>
                            <div className='d-flex justify-content-between analytics-list-div'>
                                <strong className='analytics-list-item'>Unique traffic</strong>
                                <span className='analytics-list-click-count'>01</span>
                            </div>
                            <div className='d-flex justify-content-between analytics-list-div'>
                                <strong className='analytics-list-item'>Noise traffic</strong>
                                <span className='analytics-list-click-count'>21</span>
                            </div>

                        </li>
                        <li className='mt-2 analytics-list'>
                            <Link className='analytics-list-anker tr2'>username</Link>
                            <div className='d-flex justify-content-between analytics-list-div'>
                                <strong className='analytics-list-item'>Unique traffic</strong>
                                <span className='analytics-list-click-count'>01</span>
                            </div>
                            <div className='d-flex justify-content-between analytics-list-div'>
                                <strong className='analytics-list-item'>Noise traffic</strong>
                                <span className='analytics-list-click-count'>21</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default DashbaordWidget;