import React, { useEffect, useState } from 'react';
import thumb from '../../assets/images/user.jpg'
import Speacer from '../driver/Speacer';
import { SuccessMessage } from '../../helper/NotificationHelper';
import axios from 'axios';
import { BioPhoto } from '../../API/APIBio';

const BioImage = ({Details}) => {

    const [Photo, setPhoto] = useState(thumb)
    const [Username, setUsername] = useState()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const username = params.get('user');
        // setPhoto(Details?.['ProfileLImg'])
        (async() => {
            setUsername(username)
        })()
    })

    const onSubmit = (event) => {
        event.preventDefault()
    }
    const UploadPicture = async (event) => {
        const file = event.target.files[0];
        if (file) {
                const formData = new FormData();
                formData.append('file', file);
                try {
                const response = await axios.post('https://mamas.profile-of.com/wp-json/wp/v2/media', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });
                setPhoto(response.data.source_url);
                console.log('Upload success:', response.data.source_url);
                } catch (error) {
                console.error('Error uploading image:', error);
                }
            }
        
    }
    const SetPicture = async () => {
        const PostBody = {ProfileLImg: Photo}
        const upload = await BioPhoto(Username, PostBody)
        if(upload === true){
            SuccessMessage('Bio picture set success')
        }
    }
    return (
        <form onSubmit={onSubmit} className='center-midle'>
            <div className="bio-update-img-box ">
                <div className=' up-69'>
                    <img  src={Photo} alt="bio tr3" className='rounded-circle'/>
                    <input onChange={UploadPicture} type="file" title='Choose bio photo'/>
                </div>
            </div>
            <Speacer Space={20}/>
            <button onClick={SetPicture} className='set-bio-photo-btn mbtn' style={{fontSize: '13px !important'}}>Set it as bio photo</button>
        </form>
    );
};

export default BioImage;