import React from 'react';
import img from '../../assets/images/roy profile img.jpeg'
import { UserMenu } from '../../helper/UserMenuLists';
import { getUserDetails } from '../../helper/sessionHelper';


const UserMenuHandle = () => {

  const UserDetails = JSON.parse(getUserDetails())

  const Logout = () => {
    localStorage.removeItem('expiration')
    localStorage.removeItem('token')
    localStorage.removeItem('userdetails')
    window.location.href = '/'
  }

  return (
    <div className='mamas-user-menu-handle parentTR' style={{zIndex: '5'}}>
        <div className="mamas-user-profile-box d-flex align-items-center">
            <img src='https://mamas.profile-of.com/wp-content/uploads/2024/12/demo-profile-1.jpeg' alt="img" />
            <h2>{UserDetails['fullName']}</h2>
        </div>
        <div style={{width: '100%', height: '1px', background: 'var(--nav-border)', margin: '10px 0px'}}></div>
        <div className='mamas-settings-menu'>
          {
            UserMenu.map((item, index) =>
              <a href={item['to']} key={index.toString()} className='d-flex justify-content-between tr3'>
                <span>{item['name']}</span>
                <i className={item['icon']}></i>
              </a>
            )
          }

          <a onClick={Logout} className='d-flex justify-content-between tr3 pointer'>
                <span>Logout</span>
                <i class="bi bi-box-arrow-right"></i>
          </a>
        </div>
        <p> <strong>MAMA'S</strong> is always active to help you with any situation</p>

    </div>
  );
};

export default UserMenuHandle;