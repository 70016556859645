import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from '../state-slices/settings-slice'
import subscriptionReducer from '../state-slices/subscription-slice'
import bioReducer from '../state-slices/bio-slice'

export default configureStore({
    reducer: {
        settings: settingsReducer,
        subscription: subscriptionReducer,
        bio: bioReducer
    }
})