import React, { useRef, useState } from 'react';
import Logo from './Logo';
import userImg from '../../assets/images/roy profile img.jpeg'
import UserMenuHandle from './UserMenuHandle';
import { getUserDetails } from '../../helper/sessionHelper';
import { Link } from 'react-router-dom';


const UserNavigation = () => {

    let themeIconRef, mamasMenuRef = useRef()
    const [ThemeIcon, SetThemeIcon] = useState('bi bi-sun-fill')

    const UserDetails = JSON.parse(getUserDetails())


    const ChangeTheme = () => {
        const themeIcon = themeIconRef;
        const body = document.querySelector('body')
        body.classList.toggle('white-mode')
        if(themeIcon.classList.contains('bi-sun-fill')){
            SetThemeIcon('bi-moon-stars-fill')
            themeIcon.style.color = '#FFF'
        }else if(themeIcon.classList.contains('bi-moon-stars-fill')){
            SetThemeIcon('bi-sun-fill')
            themeIcon.style.color = 'wheat'

        }

    }

    const UserMenuHandler = () => {
        const mamasMenu = mamasMenuRef;
        if(mamasMenu.classList.contains('close-mamas-user-meanu')){
            mamasMenu.classList.remove('close-mamas-user-meanu')
            mamasMenu.classList.add('open-mamas-user-meanu')
        }else{
            mamasMenu.classList.remove('open-mamas-user-meanu')
            mamasMenu.classList.add('close-mamas-user-meanu')
        }
    }


    return (

            <div className="mamas-navbar parentTR">
                <div className="container">
                    <div className="row" style={{position: 'relative'}}>
                        <div className="col-lg-4 col-md-4 col-6 mamass-logo">
                            <Logo/>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4 invisible-sm">
                            <div className='center-center'>
                                <a href="#" className='earn-money-mamas'>Earn money from <span>mamma's</span></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6">
                            <div className="mamas-top-nav-icons">



                                <Link to='/dashboard' className="mamas-order-icons">
                                    <i class="bi bi-house-fill tr2"></i>
                                </Link>

                                <Link to='/' className="mamas-order-icons">
                                    <i className="bi bi-cart-check-fill tr2"></i>
                                </Link>

                                <div className="mamas-theme-mood">
                                    <i onClick={ChangeTheme} ref={(i)=>themeIconRef=i} className={`bi ${ThemeIcon}`}></i>
                                </div>

                                <Link to='https://www.facebook.com/profile.php?id=61563921453660' className="mamas-social-icons">
                                    <i className="bi bi-facebook tr2"></i>
                                </Link>

                                <div onClick={UserMenuHandler}  className="mamas-user-img">
                                    <img src='https://mamas.profile-of.com/wp-content/uploads/2024/12/demo-profile-1.jpeg' alt="img" className='tr2'/>
                                </div>


                            </div>
                        </div>
                        <div ref={(i)=> mamasMenuRef=i} className='close-mamas-user-meanu tr2'>
                            <UserMenuHandle/>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default UserNavigation;