export const UserMenu = [
    {
        name: 'Dashboard',
        icon: 'bi bi-caret-right',
        to: '/dashboard'
    },
    {
        name: 'Settings & privacy',
        icon: 'bi bi-caret-right',
        to: 'https://google.com'
    },
    {
        name: 'Security & policy',
        icon: 'bi bi-caret-right',
        to: 'https://google.com'
    },
    {
        name: 'Help & supports',
        icon: 'bi bi-caret-right',
        to: 'https://google.com'
    },
    {
        name: 'Become a affiliate marketor',
        icon: 'bi bi-caret-right',
        to: 'https://google.com'
    },
    {
      name: 'Earnings',
      icon: 'bi bi-caret-right',
      to: 'https://google.com'
  }
]

