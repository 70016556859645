import React, { useRef, useState } from 'react';
import Logo from './Logo';
import userImg from '../../assets/images/roy profile img.jpeg'
import UserMenuHandle from './UserMenuHandle';
import { GetAuthDetails } from '../../helper/sessionHelper';
import { Link } from 'react-router-dom';

const LogoutNav = () => {

    let themeIconRef, mamasMenuRef = useRef()
    const [ThemeIcon, SetThemeIcon] = useState('bi bi-sun-fill')


    const ChangeTheme = () => {
        const themeIcon = themeIconRef;
        const body = document.querySelector('body')
        body.classList.toggle('white-mode')
        if(themeIcon.classList.contains('bi-sun-fill')){
            SetThemeIcon('bi-moon-stars-fill')
            themeIcon.style.color = '#FFF'
        }else if(themeIcon.classList.contains('bi-moon-stars-fill')){
            SetThemeIcon('bi-sun-fill')
            themeIcon.style.color = 'wheat'

        }

    }

    const UserMenuHandler = () => {
        const mamasMenu = mamasMenuRef;
        if(mamasMenu.classList.contains('close-mamas-user-meanu')){
            mamasMenu.classList.remove('close-mamas-user-meanu')
            mamasMenu.classList.add('open-mamas-user-meanu')
        }else{
            mamasMenu.classList.remove('open-mamas-user-meanu')
            mamasMenu.classList.add('close-mamas-user-meanu')
        }
    }


    return (

            <div className="mamas-navbar parentTR">
                <div className="container">
                    <div className="row" style={{position: 'relative'}}>
                        <div className="col-lg-4 col-md-4 col-6 mamass-logo">
                            <Logo/>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4 invisible-sm">
                            <div className='center-center'>
                                <a href="#" className='earn-money-mamas'>Earn money from <span>mamma's</span></a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-6">
                            <div className="mamas-top-nav-icons">



                                <Link to='/' className="mamas-order-icons">
                                    <i class="bi bi-house-fill tr2"></i>
                                </Link>

                                <div className="mamas-order-icons">
                                    <i className="bi bi-cart-check-fill tr2"></i>
                                </div>

                                <div className="mamas-theme-mood">
                                    <i onClick={ChangeTheme} ref={(i)=>themeIconRef=i} className={`bi ${ThemeIcon}`}></i>
                                </div>

                                <div className="mamas-social-icons">
                                    <i className="bi bi-facebook tr2"></i>
                                </div>

                                <Link to='/login' className="mamas-login-icon">
                                    <i class="bi bi-box-arrow-in-right"></i>
                                </Link>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

    );
};

export default LogoutNav;