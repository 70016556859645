import { toast, Slide } from 'react-toastify';

class NoticeHelper{

    RequiredMessage(msg){
        return toast.warn(msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
        });
    }

    SuccessMessage(msg){
        toast.success(msg, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide,
        });
    }


}

export const { RequiredMessage, SuccessMessage } = new NoticeHelper()