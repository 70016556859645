import { createSlice } from "@reduxjs/toolkit";

export const BioSlice = createSlice({
    name: 'bio',
    initialState: {
        List: [],
        BioDetail: []
    },
    reducers: {
        setList: (state, action) => {
            state.List = action.payload
        },
        setBioDetail: (state, action) => {
            state.BioDetail = action.payload
        }
        
    }
})

export const { setList, setBioDetail } = BioSlice.actions;
export default BioSlice.reducer;