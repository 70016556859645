import React, { Fragment, Suspense } from 'react';
import ApproveForm from '../../components/forms/ApproveForm';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';

const Approval = () => {
    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                    <ApproveForm/>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default Approval;