import React, { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import bKash from '../../assets/images/qr-code.png'
import { CreateOrderRequest } from '../../API/APIOrder';
import { IsEmpty } from '../../helper/FormHelper';
import { RequiredMessage } from '../../helper/NotificationHelper';


const PurchasePage = () => {

    const [Price, setPrice] = useState()
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const cost = params.get('price');
        (async() => {
            setPrice(cost)
        })()
    }, [0])

    let numberRef, SenderNumberRef, TransactionRef = useRef()

    const copyNumber = () => {
        const number = numberRef.textContent;
        navigator.clipboard.writeText(number)
    }

    const ConfirmOrder = async () => {
        const Sender = SenderNumberRef.value;
        const TransactionId = TransactionRef.value;
        const SubID = localStorage.getItem('SubID')
        const PostBody = {MoneySender: Sender, TransactionId: TransactionId}
        if(IsEmpty(Sender)){
            RequiredMessage('Enter Money sender number')
        }else if(IsEmpty(TransactionId)){
            RequiredMessage('Enter Transaction ID')
        }else{
            const create = await CreateOrderRequest(SubID, PostBody)
            if(create === true){
                window.location.href = "/"
            }
        }

    }

    return (

        <Fragment>
        <ParentLayout>
            <Suspense fallback={<MamasLoader2/>}>
                <Fragment>
                    <div className="container my-3" >
                        <div className="row justify-content-center" >
                            <div className="col-md-7 col-lg-6 " style={{background: 'var(--body)'}}>
                                <div className=" w-90  p-4" >
                                    <div className="card-body" style={{boxShadow: 'var(--shadow2)', borderRadius: '8px'}}>
                                        <h4 style={{color: 'var(--title2)', fontSize: '15px'}}><strong>bKash</strong> Send money</h4>
                                        <p className='text'>Scan the QR code below and send <strong style={{background: 'var(--nav-bg)', padding: '1px 10px', color: 'var(--color)', borderRadius: '25px', fontSize: '13px'}}>{Price}</strong> taka. After sending the money, press the 'Confirm Order' button with the transaction ID and the number sent.</p>
                                        <div className='center-midle' style={{width: '100%'}}>
                                            <img src={bKash} alt="qr-code" style={{width: '40%'}} />
                                            <h2 className='title1 sender-number my-2'>
                                                <span ref={(span)=> numberRef=span}>+8801709200987</span>
                                                <i onClick={copyNumber} class="bi bi-copy"></i>
                                            </h2>
                                        </div>
                                        
                                        <label htmlFor="number">Sender Number</label>
                                        <input ref={(input)=>SenderNumberRef=input} placeholder="Sender Number" id='number' className="form-control animated fadeInUp" type="text" style={{background: 'transparent', border: '1px solid var(--color2)'}}/>
                                        
                                        <label htmlFor="transid">Transaction ID</label>
                                        <input ref={(input)=>TransactionRef=input} placeholder="Transaction ID" id='transid' className="form-control animated fadeInUp" type="text" style={{background: 'transparent', border: '1px solid var(--color2)'}}/>

                                        <button onClick={ConfirmOrder} className='btn login-regist mt-4' style={{width: '100%', color: 'var(--color)', letterSpacing: '1px'}}>Confirm order</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>

            </Suspense>
        </ParentLayout>
    </Fragment>


    );
};

export default PurchasePage;