import React, { Fragment } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import UserHome from './pages/loginuser/UserHome';
import LoginPage from './pages/logouts/LoginPage';
import MamasLoader2 from './MasterLayout/loading/MamasLoader2';
import ImageUpload from './components/forms/ImageUpload';
import SinglePackPage from './pages/loginuser/SinglePackPage';
import PurchasePage from './pages/loginuser/PurchasePage';
import SingleBioPage from './pages/public/SingleBioPage';
import Dashboard from './pages/loginuser/Dashboard';
import SignupPage from './pages/logouts/SignupPage';
import Approval from './pages/logouts/Approval';
import { ToastContainer } from 'react-toastify';
import VerifyEmailPage from './pages/logouts/VerifyEmailPage';
import EmailOTPVerify from './pages/logouts/EmailOTPVerify';
import NewPasswordPage from './pages/logouts/NewPasswordPage';
import UpdateBioPage from './pages/loginuser/UpdateBioPage';
import ThemeCustomizationPage from './pages/loginuser/ThemeCustomizationPage';
import { getToken } from './helper/sessionHelper';



const App = () => {

  if(getToken()){
    
    return (
      <Fragment>
        <BrowserRouter>
          <Routes>
  
            <Route exact path='/' element={<UserHome/>}/>
  


            <Route exact path='/upload' element={<ImageUpload/>}/>
  
            <Route exact path='/package-details' element={<SinglePackPage/>}/>
            <Route exact path='/purchase' element={<PurchasePage/>}/>
  
  
  
            <Route exact path='/dashboard' element={<Dashboard/>}/>
            <Route exact path='/update-bio' element={<UpdateBioPage/>}/>
            <Route exact path='/customization' element={<ThemeCustomizationPage/>}/>
            <Route exact path='/bio' element={<SingleBioPage/>}/>
  
  
          </Routes>
          <MamasLoader2/>
          <ToastContainer/>
        </BrowserRouter>
      </Fragment>
    );

  }else{
    return (
      <Fragment>
        <BrowserRouter>
          <Routes>
            <Route exact path='/dashboard' element={<Navigate to='/login' replace/>}/>
            <Route exact path='/' element={<Navigate to='/login' replace/>} />
  
            <Route exact path='/login' element={<LoginPage/>}/>
            <Route exact path='/signup' element={<SignupPage/>}/>
            <Route exact path='/approve' element={<Approval/>}/>
  
            <Route exact path='/verifymail' element={<VerifyEmailPage/>}/>
            <Route exact path='/newPassword' element={<NewPasswordPage/>}/>
            <Route exact path='/bio' element={<SingleBioPage/>}/>
  
          </Routes>
          <MamasLoader2/>
          <ToastContainer/>
        </BrowserRouter>
      </Fragment>
    );
  }
  
};

export default App;