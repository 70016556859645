import axios from "axios"
import { BaseURL } from "../helper/Config"
import { HideLoader2, ShowLoader2 } from "../redux/state-slices/settings-slice"
import store from "../redux/store/store"
import { setDetails, setSubList } from "../redux/state-slices/subscription-slice"
import { getToken } from "../helper/sessionHelper"



const AxiosToken = {headers: {'token': getToken()}}

export async function GetSubscriptionList() {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/subscriptionList"
        const res = await axios.get(URL)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            // console.log(res?.data?.data)
            store.dispatch(setSubList(res?.data?.data))
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}


export async function GetSubscriptionDetails(SubId) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/subscriptionDetails/"+SubId
        const res = await axios.get(URL, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200){
            store.dispatch(setDetails(res?.data?.data?.data))
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}