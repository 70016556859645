import axios from "axios"
import { BaseURL } from "../helper/Config"
import { HideLoader2, ShowLoader2 } from "../redux/state-slices/settings-slice"
import store from "../redux/store/store"
import { getToken } from "../helper/sessionHelper"

const AxiosToken = {headers: {'token': getToken()}}

export async function MatchCupuon(PackID, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/cuponMatch/"+PackID
        const res = await axios.post(URL, PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res?.data?.message === 'Apply valid coupon'){
            return 'invalid'
        }else if(res.status === 200 && res.data.status === 'success'){
            return res
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function CreateOrderRequest(SubID, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/createOrder/"+SubID
        const res = await axios.post(URL, PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function IsActiveOrder() {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/IsActiveOrder"
        const res = await axios.get(URL, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200){
            console.log(res)
            return res
        }else{
            return false
        }

    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}