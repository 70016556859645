import React from 'react';

const HomeWidget = () => {
    return (
        <div>
            <div className='mamas-widget-area parentTR'>
                <h2>Direct support</h2>
                <div className="suppor-social d-flex justify-content-between pt-2">
                    <a href="#">WHATSAPP<i class="bi bi-whatsapp"></i></a>
                    <a href="#">FACEBOOK<i class="bi bi-facebook"></i></a>
                </div>
            </div>

            <div className='mamas-widget-area parentTR mt-3'>
                <h2>MAMAS Community Standards</h2>

                <p>If you violate the guidelines of mamas in any way, your account may be restricted. Mamas' slogan is "Stand by us with integrity, we are by your side."</p>
                    
                <h4>Avoid violations.</h4>
                <ul>
                    <li>loading...</li>
                    <li>loading...</li>
                    <li>loading...</li>
                    <li>loading...</li>
                </ul>
            </div>
        </div>
    );
};

export default HomeWidget;