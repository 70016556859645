import React, { Fragment, useState } from 'react';
import Speacer from '../driver/Speacer';
import { getEmail } from '../../helper/sessionHelper';
import { ApprovalRequest } from '../../API/APIUser';
import ReactCodeInput from 'react-code-input';
import OTPInput from 'react-otp-input';

const ApproveForm = () => {

    const inputStyle = {
        width: '35px',
        height: '35px', 
        fontSize: '24px',
        textAlign: 'center',
        border: '2px solid #ccc',
        borderRadius: '8px'
      };

    const [OTP, setOTP] = useState()

    const formSubmit = (event) => {
        event.preventDefault()
    }

    const ApproveUser =async () => {
        const email = getEmail()
        const res = await ApprovalRequest(email, OTP)
        if(res === true){
            localStorage.removeItem('email')
            window.location.href= '/login'
        }
    }

    return (
        <Fragment>
            <div className="container" style={{height: '85vh'}}>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-7 col-12">
                        <form onSubmit={formSubmit} className='mamas-login-form-area center-midle' style={{height: '85vh'}}>
                            <div className="login-container" style={{ boxShadow: 'var(--shadow1)', padding: '20px 15px'}}>
                                <div className="login-box " style={{background: '#0f0f0f !important'}}>
                                <div className="login-header">
                                    <i className="login-icon"></i>
                                </div>
                                <h2 className='title1 ' style={{fontSize: '20px', lineHeight: '30px', textAlign: 'center'}}>OTP Verify</h2>
                                <p className="text" >Put 6 digit OTP code & sent profile approval request</p>
                                
                                <div className=" center-midle" >

                                    <OTPInput value={OTP} onChange={(value) => setOTP(value)} inputStyle={inputStyle} numInputs={6}renderSeparator={<span>-</span>}renderInput={(props) => <input {...props} />}/>

                                    <Speacer Space={20} />
                                    <button onClick={ApproveUser} className="btn tr3" style={{background: 'var(--nav-bg)', letterSpacing: '1px', color: 'var(--color)'}}>Approval request</button>
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ApproveForm;