import React, { Fragment, Suspense } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import EmailVerifyForm from '../../components/forms/EmailVerifyForm';

const VerifyEmailPage = () => {
    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                    <EmailVerifyForm/>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default VerifyEmailPage;