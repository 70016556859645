import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import Speacer from '../driver/Speacer';
import { RequiredMessage, SuccessMessage } from '../../helper/NotificationHelper';
import { BioUpdate } from '../../API/APIBio';
import { IsEmpty } from '../../helper/FormHelper';

const ButtonField = ({Details}) => {

    const onSubmit = (event) => {
        event.preventDefault()
    }

    // button label
    const [Label1, setLabel1] = useState('')
    const [Label2, setLabel2] = useState('')
    const [Label3, setLabel3] = useState('')
    const [Label4, setLabel4] = useState('')
    const [Label5, setLabel5] = useState('')

    // button link
    const [Link1, setLink1] = useState('')
    const [Link2, setLink2] = useState('')
    const [Link3, setLink3] = useState('')
    const [Link4, setLink4] = useState('')
    const [Link5, setLink5] = useState('')

    // button link
    const [Social1, setSocial1] = useState('')
    const [Social2, setSocial2] = useState('')
    const [Social3, setSocial3] = useState('')
    const [Social4, setSocial4] = useState('')
    const [Social5, setSocial5] = useState('')

    useEffect(() => {
        setSocial1(Details?.['SocialLinks']?.['WhatsApp']?.['link'])
        setSocial2(Details?.['SocialLinks']?.['Telegram']?.['link'])
        setSocial3(Details?.['SocialLinks']?.['Linkedin']?.['link'])
        setSocial4(Details?.['SocialLinks']?.['Facebook']?.['link'])
        setSocial5(Details?.['SocialLinks']?.['Instagram']?.['link'])


        setLabel1(Details?.['Buttons']?.[0]?.['label'])
        setLabel2(Details?.['Buttons']?.[1]?.['label'])
        setLabel3(Details?.['Buttons']?.[2]?.['label'])
        setLabel4(Details?.['Buttons']?.[3]?.['label'])
        setLabel5(Details?.['Buttons']?.[4]?.['label'])

        setLink1(Details?.['Buttons']?.[0]?.['link'])
        setLink2(Details?.['Buttons']?.[1]?.['link'])
        setLink3(Details?.['Buttons']?.[2]?.['link'])
        setLink4(Details?.['Buttons']?.[3]?.['link'])
        setLink5(Details?.['Buttons']?.[4]?.['link'])

    }, [Details])





    // setTimeout(() => {

    //     const social1 = Details?.['SocialLinks']?.['WhatsApp']?.['link']
    //     const social2 = Details?.['SocialLinks']?.['Telegram']?.['link']
    //     const social3 = Details?.['SocialLinks']?.['Linkedin']?.['link']
    //     const social4 = Details?.['SocialLinks']?.['Facebook']?.['link']
    //     const social5 = Details?.['SocialLinks']?.['Instagram']?.['link']

    //     setSocial1(social1)
    //     setSocial2(social2)
    //     setSocial3(social3)
    //     setSocial4(social4)
    //     setSocial5(social5)

    //     const label1 = Details?.['Buttons']?.[0]?.['label']
    //     const label2 = Details?.['Buttons']?.[1]?.['label']
    //     const label3 = Details?.['Buttons']?.[2]?.['label']
    //     const label4 = Details?.['Buttons']?.[3]?.['label']
    //     const label5 = Details?.['Buttons']?.[4]?.['label']

    //     const link1 = Details?.['Buttons']?.[0]?.['link']
    //     const link2 = Details?.['Buttons']?.[1]?.['link']
    //     const link3 = Details?.['Buttons']?.[2]?.['link']
    //     const link4 = Details?.['Buttons']?.[3]?.['link']
    //     const link5 = Details?.['Buttons']?.[4]?.['link']


    //     setLabel1(label1)
    //     setLabel2(label2)
    //     setLabel3(label3)
    //     setLabel4(label4)
    //     setLabel5(label5)

    //     setLink1(link1)
    //     setLink2(link2)
    //     setLink3(link3)
    //     setLink4(link4)
    //     setLink5(link5)
    //     console.log(Link1)

    // }, 1000)








      const UpdateButton =async () => {
        const username =  Details['UserName']
        const PostBody = {
            Buttons: [
              { label: Label1, link: Link1 },
              { label: Label2, link: Link2 },
              { label: Label3, link: Link3 },
              { label: Label4, link: Link4 },
              { label: Label5, link: Link5 }
            ]
          };
    

          if(IsEmpty(Label1)){
            RequiredMessage('Labe-1*')
          }else{
            const update = await BioUpdate(username, PostBody)
            if (update === true){
              SuccessMessage('Link update success')
            }
          }


      }

      const UpdateSocial = async () => {
        const username =  Details['UserName']
        const PostBody = {
            SocialLinks: {
              WhatsApp: { icon: '', link: Social1 },
              Telegram: { icon: '', link: Social2 },
              Linkedin: { icon: '', link: Social3 },
              Facebook: { icon: '', link: Social4 },
              Instagram: { icon: '', link: Social5 },
            }
          };
          const update = await BioUpdate(username, PostBody)
          if (update === true){
            SuccessMessage('Social update success')
          }
      }

    return (
        <div className='parents-of-social-buttons'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Brand links</Accordion.Header>
                    <Accordion.Body>
                        <form onSubmit={onSubmit} className='buttons-update'>

                        {
                            Details?.['Buttons']?.[0]?.['link'] ? (
                                <div>
                                    <p className='text' style={{marginBottom: '2px'}}>Button 1*</p>
                                    <div className='btn-item'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label htmlFor="label1">Label</label>
                                            <input onChange={(e)=> setLabel1(e.target.value)} type="text" defaultValue={Label1} id='label1'/>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label htmlFor="link1">Link</label>
                                            <input onChange={(e)=> setLink1(e.target.value)} type="text" defaultValue={Link1} id='link1'/>
                                        </div>
                                    </div>
                                </div>
                            ) : (" ")
                        }

                        {
                            Details?.['Buttons']?.[1]?.['link'] ? ( 
                                <div>
                                    <Speacer Space={10} />
                                    <p className='text' style={{marginBottom: '2px'}}>Button 2*</p>
                                    <div className='btn-item'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label htmlFor="label2">Label</label>
                                            <input onChange={(e)=> setLabel2(e.target.value)} type="text" defaultValue={Label2} id='label2'/>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label htmlFor="link2">Link</label>
                                            <input onChange={(e)=> setLink2(e.target.value)} type="text" defaultValue={Link2} id='link2'/>
                                        </div>
                                    </div>
                                </div>
                             ) : ("")
                        }

                        {
                            Details?.['Buttons']?.[2]?.['link'] ? ( 
                                <div>
                                    <Speacer Space={10} />
                                    <p className='text' style={{marginBottom: '2px'}}>Button 3*</p>
                                    <div className='btn-item'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label htmlFor="label3">Label</label>
                                            <input onChange={(e)=> setLabel3(e.target.value)} type="text" defaultValue={Label3} id='label3'/>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label htmlFor="link3">Link</label>
                                            <input onChange={(e)=> setLink3(e.target.value)} type="text" defaultValue={Link3} id='link3'/>
                                        </div>
                                    </div>
                                </div>
                             ) : ("")
                        }


                        {
                            Details?.['Buttons']?.[3]?.['link'] ? ( 
                                <div>
                                    <Speacer Space={10} />
                                    <p className='text' style={{marginBottom: '2px'}}>Button 4*</p>
                                    <div className='btn-item'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label htmlFor="label4">Label</label>
                                            <input onChange={(e)=> setLabel4(e.target.value)} type="text" defaultValue={Label4} id='label4'/>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label htmlFor="link4">Link</label>
                                            <input onChange={(e)=> setLink4(e.target.value)} type="text" defaultValue={Link4} id='link4'/>
                                        </div>
                                    </div>
                                </div>
                             ) : ("")
                        }

                        {
                             Details?.['Buttons']?.[4]?.['link'] ? ( 
                                <div>
                                    <Speacer Space={10} />
                                    <p className='text' style={{marginBottom: '2px'}}>Button 5*</p>
                                    <div className='btn-item'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <label htmlFor="label5">Label</label>
                                            <input onChange={(e)=> setLabel5(e.target.value)} type="text" defaultValue={Label5} id='label5'/>
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label htmlFor="link5">Link</label>
                                            <input onChange={(e)=> setLink5(e.target.value)} type="text" defaultValue={Link5} id='link5'/>
                                        </div>
                                    </div>
                                </div>
                              ) : ("")
                        }

                            

                        <Speacer Space={20}/>
                        <button onClick={UpdateButton} className='set-bio-photo-btn mbtn' style={{fontSize: '13px !important'}}>Save button</button>


                        </form>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Social links</Accordion.Header>
                    <Accordion.Body>
                        <form onSubmit={onSubmit} className='buttons-update'>
                                <div>
                                    <div className='btn-item social-link'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <i class="bi bi-whatsapp"></i>
                                            <input onChange={(e)=> setSocial1(e.target.value)} type="text" defaultValue={Social1} id='label1'/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='btn-item social-link'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <i class="bi bi-telegram"></i>
                                            <input onChange={(e)=> setSocial2(e.target.value)} type="text" defaultValue={Social2} id='label1'/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='btn-item social-link'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <i class="bi bi-linkedin"></i>
                                            <input onChange={(e)=> setSocial3(e.target.value)} type="text" defaultValue={Social3} id='label1'/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='btn-item social-link'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <i class="bi bi-facebook"></i>
                                            <input onChange={(e)=> setSocial4(e.target.value)} type="text" defaultValue={Social4} id='label1'/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='btn-item social-link'>
                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                            <i class="bi bi-instagram"></i>
                                            <input onChange={(e)=> setSocial5(e.target.value)} type="text" defaultValue={Social5} id='label1'/>
                                        </div>
                                    </div>
                                </div>
                                <Speacer Space={20}/>
                                <button onClick={UpdateSocial} className='set-bio-photo-btn mbtn' style={{fontSize: '13px !important'}}>Save social</button>
                        </form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default ButtonField;