import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import BioImage from './BioImage';
import ThemeCustomization from './ThemeCustomization';
import ButtonCustomization from './ButtonCustomization';
import ButtonField from './ButtonField';

const BioWidget = ({Details}) => {

    const SaveData = () => {
        window.location.href = `/customization?user=${Details?.['UserName']}`
    }

    return (
        <div>
            <div className='mamas-widget-area parentTR mt-3'>
                <Tabs defaultActiveKey="Content" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="Content" title="Content">
                        <ButtonField Details={Details}/>
                    </Tab>
                    <Tab eventKey="Upload" title="Upload">
                        <BioImage Details={Details}/>
                    </Tab>
                    <Tab  eventKey="Theme" title="Theme">
                        {/* <ThemeCustomization Details={Details} /> */}
                        <button onClick={SaveData} className='set-bio-photo-btn mbtn tr2' style={{fontSize: '13px !important'}}>Theme customization stream</button>

                        
                    </Tab>

                    {/* <Tab eventKey="Button" title="Button">
                        <ButtonCustomization/>
                    </Tab> */}
                </Tabs>
            </div>
        </div>
    );
};

export default BioWidget;