import React, { useEffect, useRef, useState } from 'react';
import thumbnail from '../../assets/images/mamas-baner.jpg'
import { Link } from 'react-router-dom';
import { GetSubscriptionDetails } from '../../API/APISubscription';
import CuopnForm from '../forms/CuopnForm';
import { useSelector } from 'react-redux';

const PackageDetails = () => {

    let CuponFormComponentRef = useRef();
    const body = document.querySelector('body')
    const [SubID, setSubID] = useState()
    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const SubId = params.get('id');

        (async() => {
            setSubID(SubId)
            await GetSubscriptionDetails(SubId)
        })()
    }, [0])

    const SubDetails = useSelector((state) => (state.subscription.Details))


    const matchCuopon = () => {
        const FormComponent = CuponFormComponentRef;
        if(FormComponent.classList.contains('hidden')){
            FormComponent.classList.remove('hidden')
            FormComponent.classList.add('visiblity')
            
        }
    }

    const  hideCuponForm = () => {
        const FormComponent = CuponFormComponentRef;
        if(FormComponent.classList.contains('visiblity')){
            FormComponent.classList.remove('visiblity')
            FormComponent.classList.add('hidden')
        }
    }

    const saveSubId = () => {
        localStorage.setItem('SubID', SubID)
    }



    return (
        <div style={{position: 'relative'}}> 
             <div className='baner-one center-midle'>
                <h1 className='text-center'>{SubDetails?.['Name']}</h1>
                <span>Package</span>
             </div>

             <div className='container'>
                <div className="row">
                    <div className="row g-0 my-4">
                    <div className="col-lg-6 col-md-12">
                        <div className="card-body">
                        <h5 className="card-title title1" style={{fontWeight: '600'}}>{SubDetails?.['Name']}</h5>
                        <div className="d-flex align-items-center mb-2" style={{color: 'var(--title2)'}}>
                            <span className="me-2">4.8</span>
                            <span>(03 Ratings)</span>
                        </div>
                        <p className="card-text text">
                        We are grateful to have realized your attitude of choosing us in your marketing career. Otherwise, we are sorry that we are not able to accept direct payments as our payment method has not yet received merchant approval.<br/><br/>
                                    
                        <strong>However, you can send us your transaction ID using our bKash Send Money and we will activate your order within a maximum of 1 hour.</strong>
                        </p>
                        <h4 className="title1 center-center" style={{fontSize: '25px', color: 'var(--redx)', justifyContent: 'left'}}>
                            ৳{SubDetails?.['Price']} <div onClick={matchCuopon} className='I_have_coupon pointer' style={{position: 'relative', top: '5px', left: '5px'}}>I have coupon</div>
                            
                        </h4>
                        <div className="d-flex flex-wrap gap-2 mb-3">
                        <span className="features-list">{SubDetails?.['Features']}</span>
                            {/* {
                                SubDetails['Features'].split(', ').map((list, index) => (
                                    <span key={index.toString()} className="features-list">{list}</span>
                                ))
                            } */}
                        </div>
                        <Link onClick={saveSubId} to={`/purchase?price=${SubDetails?.['Price']}`} className="btn" style={{background: 'var(--redx)', color: 'var(--color)'}}>Purchase Now</Link>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 position-relative">
                    <img src={thumbnail} alt="thumbnail" style={{width: '100%', height: '100%', borderRadius: '2px', boxShadow: 'var(--shadow3)'}}/>
                    </div>
                    </div>
                    <div style={{width: '100%', borderBottom: '1px solid var(--widgetBorder1)' , padding: '5px 0px'}}></div>
                        <p className='text py-5'>{SubDetails?.['Description']}</p>
                    </div>
             </div>
             <div ref={(div)=> CuponFormComponentRef =div} className='hidden'>
                <CuopnForm onClick={hideCuponForm}/>
             </div>
        </div>
    );
};

export default PackageDetails;