import React, { Fragment, Suspense } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import PackageDetails from '../../components/home/PackageDetails';

const SinglePackPage = () => {
    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>

                    <PackageDetails/>

                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default SinglePackPage;