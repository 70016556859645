import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        loader: 'd-none',
        loader2: 'd-none'
    },
    reducers: {
        ShowLoader: (state) => {
            state.loader =""
        },
        HideLoader: (state) => {
            state.loader ="d-none"
        },
        ShowLoader2: (state) => {
            state.loader2 =""
        },
        HideLoader2: (state) => {
            state.loader2 ="d-none"
        }
    }
})

export const {ShowLoader, HideLoader, ShowLoader2, HideLoader2} = settingsSlice.actions;
export default settingsSlice.reducer;