import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

const MamasLoader2 = () => {

    const loader2 = useSelector((state) => (state.settings.loader2))

    return (
        <Fragment>
            <div className={loader2 + ' mamas-loader-body'} style={{zIndex: '2'}}>
                <div className='mamas-primary-loader2'>
                    <span style={{"--i":1}}>M</span>
                    <span style={{"--i":2}}>A</span>
                    <span style={{"--i":3}}>M</span>
                    <span style={{"--i":4}}>A</span>
                    <span style={{"--i":5, position: 'relative', fontSize: '20px', top: '-20px'}}>•</span>
                    <span style={{"--i":6}}>S</span>
                </div>
            </div>
        </Fragment>
    );
};

export default MamasLoader2;