import React, { useState } from 'react';
import axios from 'axios';

function ImageUpload() {


  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
        const formData = new FormData();
        formData.append('file', file);
        try {
        const response = await axios.post('https://mamas.profile-of.com/wp-json/wp/v2/media', formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        });
        setSelectedImage(response.data.source_url);
        console.log('Upload success:', response.data.source_url);
        } catch (error) {
        console.error('Error uploading image:', error);
        }
    }
  };


  return (
    <div>
      <input type="file" onChange={handleImageChange} />
        <br/>
        <img src={selectedImage} alt="img" />

    </div>
  );
}

export default ImageUpload;
