import React, { Fragment } from 'react';
import Footer from './Footer';
import UserNavigation from './UserNavigation';

import LogoutNav from './LogoutNav';
import { getExpiration, getToken } from '../../helper/sessionHelper';


const ParentLayout = (props) => {



    const AxiosToken = {headers: {'token': getToken()}}
    const expirationTime = JSON.parse(getExpiration())

    const currentTime = new Date().getTime()
    if(AxiosToken !== null){
        if(currentTime > expirationTime){
            localStorage.removeItem('expiration')
            localStorage.removeItem('token')
            localStorage.removeItem('UserDetails')
        }
    }


    if(!getToken()){
        return (
            <Fragment>
                <LogoutNav/>
                    <div>
                        {props.children}
                    </div>
                <Footer/>
            </Fragment>
        )
    }else{
        return (
            <Fragment>
                <UserNavigation/>
                <div>
                    {props.children}
                </div>
                <Footer/>
            </Fragment>
        );
    }



};

export default ParentLayout;