import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { BioDetails, BioUpdate } from '../../API/APIBio';
import { SuccessMessage } from '../../helper/NotificationHelper';
import { Link } from 'react-router-dom';
import banner from '../../assets/images/mamas-baner.jpg'
import { useSelector } from 'react-redux';
import Speacer from '../../components/driver/Speacer';
import { Tab, Tabs } from 'react-bootstrap';
import { BiosButtonUpdate, BiosThemeUpdate } from '../../API/APIBioCustom';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';


const ThemeCustomizationPage = () => {

    /// Bio content
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const username = params.get('user');
        (async() => {
            await BioDetails(username)
        })()
    }, [])

    const Details = useSelector((state) => (state.bio.BioDetail))


    const [username, Setusername] = useState()
    const [Name, setName] = useState()
    const [MainTitle, setMainTitle] = useState()

    useEffect(() => {
        setName(Details?.DisplayName)
        setMainTitle(Details?.MainTitle)
    }, [Details])



        const [ThemeBG1, setThemeBG1] = useState('');
        const [ThemeBG2, setThemeBG2] = useState('');
    
        const [FontCLR, setFontCLR] = useState('');
        const [fontSize, setfontSize] = useState('')
    
        const [ThemeW, setThemeW] = useState('');
        const [ThemeH, setThemeH] = useState('');
        const [ThemeR, setThemeR] = useState('');




        const [ButtonBG1, setButtonBG1] = useState('');
        const [ButtonBG2, setButtonBG2] = useState('');
            
        const [FontBTCR, setFontBTCR] = useState('');
        const [FontSIZ, setFontSIZ] = useState('')
            
        const [ButtonW, setButtonW] = useState('');
        const [ButtonH, setButtonH] = useState('');
        const [ButtonR, setButtonR] = useState('');
    
        // console.log(Details?.['button']?.['ButtonBG1'])

        useEffect(() => {
    
            setThemeBG1(Details?.['theme']?.['ThemeBG1'])
            setThemeBG2(Details?.['theme']?.['ThemeBG2'])
            setFontCLR(Details?.['theme']?.['FontCLR'])
            setThemeW(Details?.['theme']?.['ThemeW'])
            setThemeH(Details?.['theme']?.['ThemeH'])
            setThemeR(Details?.['theme']?.['ThemeR'])
            setfontSize(Details?.['theme']?.['FontSIZ'])

            setButtonBG1(Details?.['button']?.['ButtonBG1'])
            setButtonBG2(Details?.['button']?.['ButtonBG2'])
            setFontBTCR(Details?.['button']?.['FontCLR'])
            setFontSIZ(Details?.['button']?.['FontSIZ'])
            setButtonW(Details?.['button']?.['ButtonW'])
            setButtonH(Details?.['button']?.['ButtonH'])
            setButtonR(Details?.['button']?.['ButtonR'])
    
    
        }, [Details])
    
        const SaveThemeData = async () => {
            const ThemeID = Details?.ThemeID;
            const PostBody = {ThemeBG1: ThemeBG1, ThemeBG2: ThemeBG2, ThemeW: ThemeW, ThemeH: ThemeH, ThemeR: ThemeR, FontCLR: FontCLR, FontSIZ: fontSize};
            
            const save = await BiosThemeUpdate(ThemeID, PostBody)
            if(save === true){
                SuccessMessage('Changes saved success')
            }
        }

        const SaveButtonData = async () => {
            const BUTTNID = Details?.BUTTNID;
            const PostBody = {ButtonBG1: ButtonBG1, ButtonBG2: ButtonBG2, ButtonW: ButtonW, ButtonH: ButtonH, ButtonR: ButtonR, FontCLR: FontBTCR, FontSIZ: FontSIZ};
            
            const save = await BiosButtonUpdate(BUTTNID, PostBody)
            if(save === true){
                SuccessMessage('Changes saved success')
            }
        }

    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                <div className='container'>
                <div className="row">
                    <div className="col-lg-8">
                    <div className="forM-btn" style={{position: 'relative'}}>
                            <div className='mamas-bio-details center-midle' style={{background: `linear-gradient(45deg, ${ThemeBG1}, ${ThemeBG2})`, borderRadius: `${ThemeR}px`, width: `${ThemeW}%`, height: `${ThemeH}vh`, borderColor: '#52288491', margin: '15px auto'}}>
                                
                                <div>
                                    <img src={Details?.['ProfileLImg']} alt="bio" style={{borderColor: `${FontBTCR}`}}/>
                                </div>

                                <div>
                                    <input onChange={(e)=> setName(e.target.value)} type="text" disabled defaultValue={Name} style={{color: `${FontBTCR}`, fontSize: `${fontSize}px`, fontWeight: '600'}} />
                                </div>

                                <div>
                                    <textarea onChange={(e)=> setMainTitle(e.target.value)} defaultValue={MainTitle} style={{color: `${FontCLR}`, fontSize: `${fontSize}px`}}></textarea>
                                </div>

                                <div className='bios-socials'>
                                    {
                                        Details?.['SocialLinks']?.['WhatsApp']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['WhatsApp']?.['link']}><i class="bi bi-whatsapp"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Telegram']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Telegram']?.['link']}><i class="bi bi-telegram"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Linkedin']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Linkedin']?.['link']}><i class="bi bi-linkedin"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Facebook']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Facebook']?.['link']}><i class="bi bi-facebook"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['SocialLinks']?.['Instagram']?.['link'] ? (
                                            <Link to={Details?.['SocialLinks']?.['Instagram']?.['link']}><i class="bi bi-instagram"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                </div>

                                <div className='mamas-bios-buttons center-midle'>


                                    {
                                        Details?.['Buttons']?.[0]?.['link'] ? (
                                            <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${ButtonR}px`, background: `linear-gradient(45deg, ${ButtonBG1}, ${ButtonBG2})`, width:`${ButtonW}%`, height: `${ButtonH}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${FontSIZ}px`, color: `${FontBTCR}`}}>{Details?.['Buttons']?.[0]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['Buttons']?.[1]?.['link'] ? (
                                            <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${ButtonR}px`, background: `linear-gradient(45deg, ${ButtonBG1}, ${ButtonBG2})`, width:`${ButtonW}%`, height: `${ButtonH}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${FontSIZ}px`, color: `${FontBTCR}`}}>{Details?.['Buttons']?.[1]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['Buttons']?.[2]?.['link'] ? (
                                            <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${ButtonR}px`, background: `linear-gradient(45deg, ${ButtonBG1}, ${ButtonBG2})`, width:`${ButtonW}%`, height: `${ButtonH}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${FontSIZ}px`, color: `${FontBTCR}`}}>{Details?.['Buttons']?.[2]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        Details?.['Buttons']?.[3]?.['link'] ? (
                                            <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${ButtonR}px`, background: `linear-gradient(45deg, ${ButtonBG1}, ${ButtonBG2})`, width:`${ButtonW}%`, height: `${ButtonH}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${FontSIZ}px`, color: `${FontBTCR}`}}>{Details?.['Buttons']?.[3]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    
                                    {
                                        Details?.['Buttons']?.[4]?.['link'] ? (
                                            <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${ButtonR}px`, background: `linear-gradient(45deg, ${ButtonBG1}, ${ButtonBG2})`, width:`${ButtonW}%`, height: `${ButtonH}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${FontSIZ}px`, color: `${FontBTCR}`}}>{Details?.['Buttons']?.[4]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                            </Link>
                                        ) : (
                                            ""
                                        )
                                    }



                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-12">
                    <div>
                        <div className='mamas-widget-area parentTR mt-3'>
                                <Tabs defaultActiveKey="Theme" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab  eventKey="Theme" title="Theme">
                                    <p className='text color-picker-label'>Font color</p>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center color-box rounded-pill'>
                                            <input onChange={(e)=>setFontCLR(e.target.value)} type="color" value={FontCLR} />
                                            <span >{FontCLR}</span>
                                        </div>
                                    </div>


                                    <p className='text color-picker-label'>Linear bacground</p>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex align-items-center color-box rounded-pill'>
                                            <input onChange={(e)=>setThemeBG1(e.target.value)} type="color"  value={ThemeBG1} />
                                            <span>{ThemeBG1}</span>
                                        </div>
                                        <div className='d-flex align-items-center color-box rounded-pill'>
                                            <input onChange={(e)=>setThemeBG2(e.target.value)} type="color" value={ThemeBG2} />
                                            <span>{ThemeBG2}</span>
                                        </div>
                                    </div>

                                    <Speacer Space={10}/>
                                    <div className='d-flex justify-content-between'>
                                        <div className=' color-box rounded-pill'>
                                            <label htmlFor="Width">Width</label>
                                        <input onChange={(e)=>setThemeW(e.target.value)} type="number" placeholder={`${ThemeW}%`} className='recust' id='Width' />
                                        </div>
                                        <div className=' color-box rounded-pill'>
                                        <label htmlFor="Height">Height</label>

                                        <input onChange={(e)=>setThemeH(e.target.value)} type="number" placeholder={`${ThemeH}vh`} className='recust' id='Height'/>
                                        </div>
                                    </div>

                                    <Speacer Space={10}/>
                                    <div className='d-flex justify-content-between'>
                                        <div className=' color-box rounded-pill'>
                                            <label htmlFor="Size">Font Size</label>
                                        <input onChange={(e)=>setfontSize(e.target.value)} type="number" placeholder={`${fontSize}px`} className='recust' id='Size' />
                                        </div>
                                        <div className=' color-box rounded-pill'>
                                        <label htmlFor="Radius">Border Radius</label>

                                        <input onChange={(e)=>setThemeR(e.target.value)} type="number" placeholder={`${ThemeR}px`} className='recust' id='Radius'/>
                                        </div>
                                    </div>
                                    <Speacer Space={10}/>
                                    <button onClick={SaveThemeData} className='set-bio-photo-btn mbtn tr2' style={{fontSize: '13px !important'}}>Save now</button>
                                    </Tab>
                                    <Tab eventKey="Button" title="Button">
                                        <p className='text color-picker-label'>Font color</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center color-box rounded-pill'>
                                                <input onChange={(e)=>setFontBTCR(e.target.value)} type="color" value={FontBTCR} />
                                                <span >{FontBTCR}</span>
                                            </div>
                                        </div>


                                        <p className='text color-picker-label'>Linear bacground</p>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center color-box rounded-pill'>
                                                <input onChange={(e)=>setButtonBG1(e.target.value)} type="color" value={ButtonBG1} />
                                                <span>{ButtonBG1}</span>
                                            </div>
                                            <div className='d-flex align-items-center color-box rounded-pill'>
                                                <input onChange={(e)=>setButtonBG2(e.target.value)} type="color" value={ButtonBG2} />
                                                <span>{ButtonBG2}</span>
                                            </div>
                                        </div>

                                        <Speacer Space={10}/>
                                        <div className='d-flex justify-content-between'>
                                            <div className=' color-box rounded-pill'>
                                                <label htmlFor="Width">Width</label>
                                            <input onChange={(e)=>setButtonW(e.target.value)} type="number" placeholder={`${ButtonW}%`} className='recust' id='Width' />
                                            </div>
                                            <div className=' color-box rounded-pill'>
                                            <label htmlFor="Height">Height</label>

                                            <input onChange={(e)=>setButtonH(e.target.value)} type="number" placeholder={`${ButtonH}px`} className='recust' id='Height'/>
                                            </div>
                                        </div>

                                        <Speacer Space={10}/>
                                        <div className='d-flex justify-content-between'>
                                            <div className=' color-box rounded-pill'>
                                                <label htmlFor="Size">Font Size</label>
                                            <input onChange={(e)=>setFontSIZ(e.target.value)} type="number" placeholder={`${FontSIZ}px`} className='recust' id='Size' />
                                            </div>
                                            <div className=' color-box rounded-pill'>
                                            <label htmlFor="Radius">Border Radius</label>

                                            <input onChange={(e)=>setButtonR(e.target.value)} type="number" placeholder={`${ButtonR}px`} className='recust' id='Radius'/>
                                            </div>
                                        </div>
                                        <Speacer Space={10}/>
                                        <button onClick={SaveButtonData} className='set-bio-photo-btn mbtn tr2' style={{fontSize: '13px !important'}}>Save now</button>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default ThemeCustomizationPage;