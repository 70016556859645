import React, { Fragment, Suspense, useEffect } from 'react';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import Image from '../../assets/images/roy profile img.jpeg'
import banner from '../../assets/images/mamas bio 1.png'
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { BioDetails } from '../../API/APIBio';

const SingleBioPage = () => {

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const username = params.get('user');

    (async () => {
        await BioDetails(username)
    })()
    }, [])

    const BioDetail = useSelector((state) => (state.bio.BioDetail))
    console.log(BioDetail?.['button']?.['ButtonBG1'])

    return (
        <Fragment>
            <Suspense fallback={<MamasLoader2/>}>
                <div style={{background: '#e3e3e3'}}>
                <div className='container'>
                    <div className="row">
                        <div className="col-12">
                            <div className='mamas-bio-details center-midle' style={{background: `linear-gradient(45deg, ${BioDetail?.['theme']?.['ThemeBG1']}, ${BioDetail?.['theme']?.['ThemeBG2']}`, borderRadius: `${BioDetail?.['theme']?.['ThemeR']}px`, borderColor: '#52288491', height: `${BioDetail?.['theme']?.['ThemeH']}`, width: `${BioDetail?.['theme']?.['ThemeW']}`}}>

                                <div>
                                    <img src={BioDetail?.['ProfileLImg']} alt="bio" style={{borderColor: '#52288491'}}/>
                                </div>

                                <div>
                                    <h3 className="name pt-2" style={{color: `${BioDetail?.['theme']?.['FontCLR']}`, color: `${BioDetail?.['theme']?.['FontSIZ']}`}}>{BioDetail?.['DisplayName']}</h3>
                                </div>

                                <div>
                                    <p className="text" style={{color: `${BioDetail?.['theme']?.['FontCLR']}`, color: `${BioDetail?.['theme']?.['FontSIZ']}`}}>{BioDetail?.['MainTitle']}</p>
                                </div>

                                <div className='bios-socials'>
                                    {
                                        BioDetail?.['SocialLinks']?.['WhatsApp']?.['link'] ? (
                                            <Link to={BioDetail?.['SocialLinks']?.['WhatsApp']?.['link']}><i class="bi bi-whatsapp"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        BioDetail?.['SocialLinks']?.['Telegram']?.['link'] ? (
                                            <Link to={BioDetail?.['SocialLinks']?.['Telegram']?.['link']}><i class="bi bi-telegram"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        BioDetail?.['SocialLinks']?.['Linkedin']?.['link'] ? (
                                            <Link to={BioDetail?.['SocialLinks']?.['Linkedin']?.['link']}><i class="bi bi-linkedin"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        BioDetail?.['SocialLinks']?.['Facebook']?.['link'] ? (
                                            <Link to={BioDetail?.['SocialLinks']?.['Facebook']?.['link']}><i class="bi bi-facebook"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {
                                        BioDetail?.['SocialLinks']?.['Instagram']?.['link'] ? (
                                            <Link to={BioDetail?.['SocialLinks']?.['Instagram']?.['link']}><i class="bi bi-instagram"></i></Link>
                                        ) : (
                                            ""
                                        )
                                    }
                                </div>

                                <div className='mamas-bios-buttons center-midle'>

                                {
                                    BioDetail?.['Buttons']?.[0]?.['link'] ? (
                                            <Link to={BioDetail?.['Buttons']?.[0]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${BioDetail?.['button']?.['ButtonR']}px`, background: `linear-gradient(45deg, ${BioDetail?.['button']?.['ButtonBG1']}, ${BioDetail?.['button']?.['ButtonBG2']}`, width: `${BioDetail?.['button']?.['ButtonW']}%`, height:`${BioDetail?.['button']?.['ButtonH']}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${BioDetail?.['button']?.['FontSIZ']}px`, color: `${BioDetail?.['button']?.['FontCLR']}`, }}>{BioDetail?.['Buttons']?.[0]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                        </Link>
                                        ) : (
                                            ""
                                        )
                                    }

{
                                    BioDetail?.['Buttons']?.[1]?.['link'] ? (
                                            <Link to={BioDetail?.['Buttons']?.[1]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${BioDetail?.['button']?.['ButtonR']}px`, background: `linear-gradient(45deg, ${BioDetail?.['button']?.['ButtonBG1']}, ${BioDetail?.['button']?.['ButtonBG2']}`, width: `${BioDetail?.['button']?.['ButtonW']}%`, height:`${BioDetail?.['button']?.['ButtonH']}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${BioDetail?.['button']?.['FontSIZ']}px`, color: `${BioDetail?.['button']?.['FontCLR']}`, }}>{BioDetail?.['Buttons']?.[1]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                        </Link>
                                        ) : (
                                            ""
                                        )
                                    }

{
                                    BioDetail?.['Buttons']?.[2]?.['link'] ? (
                                            <Link to={BioDetail?.['Buttons']?.[2]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${BioDetail?.['button']?.['ButtonR']}px`, background: `linear-gradient(45deg, ${BioDetail?.['button']?.['ButtonBG1']}, ${BioDetail?.['button']?.['ButtonBG2']}`, width: `${BioDetail?.['button']?.['ButtonW']}%`, height:`${BioDetail?.['button']?.['ButtonH']}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${BioDetail?.['button']?.['FontSIZ']}px`, color: `${BioDetail?.['button']?.['FontCLR']}`, }}>{BioDetail?.['Buttons']?.[2]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                        </Link>
                                        ) : (
                                            ""
                                        )
                                    }

{
                                    BioDetail?.['Buttons']?.[3]?.['link'] ? (
                                            <Link to={BioDetail?.['Buttons']?.[3]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${BioDetail?.['button']?.['ButtonR']}px`, background: `linear-gradient(45deg, ${BioDetail?.['button']?.['ButtonBG1']}, ${BioDetail?.['button']?.['ButtonBG2']}`, width: `${BioDetail?.['button']?.['ButtonW']}%`, height:`${BioDetail?.['button']?.['ButtonH']}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${BioDetail?.['button']?.['FontSIZ']}px`, color: `${BioDetail?.['button']?.['FontCLR']}`, }}>{BioDetail?.['Buttons']?.[3]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                        </Link>
                                        ) : (
                                            ""
                                        )
                                    }
                                  
                                  {
                                    BioDetail?.['Buttons']?.[4]?.['link'] ? (
                                            <Link to={BioDetail?.['Buttons']?.[4]?.['link']} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: `${BioDetail?.['button']?.['ButtonR']}px`, background: `linear-gradient(45deg, ${BioDetail?.['button']?.['ButtonBG1']}, ${BioDetail?.['button']?.['ButtonBG2']}`, width: `${BioDetail?.['button']?.['ButtonW']}%`, height:`${BioDetail?.['button']?.['ButtonH']}px`}}>
                                            <img src={banner} alt="bio"/>
                                            <span style={{fontSize: `${BioDetail?.['button']?.['FontSIZ']}px`, color: `${BioDetail?.['button']?.['FontCLR']}`, }}>{BioDetail?.['Buttons']?.[4]?.['label']}</span>
                                            <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                        </Link>
                                        ) : (
                                            ""
                                        )
                                    }

                                    {/* <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                        <img src={banner} alt="bio"/>
                                        <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>Furnitures e.g chair, table</span>
                                        <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                    </Link>

                                    <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                        <img src={banner} alt="bio"/>
                                        <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>Furnitures e.g chair, table</span>
                                        <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                    </Link>

                                    <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                        <img src={banner} alt="bio" />
                                        <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>Furnitures e.g chair, table</span>
                                        <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                    </Link>

                                    <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                        <img src={banner} alt="bio" />
                                        <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>Furnitures e.g chair, table</span>
                                        <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                    </Link>

                                    <Link className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                                        <img src={banner} alt="bio" />
                                        <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>Furnitures e.g chair, table</span>
                                        <i class="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
                                    </Link> */}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </Suspense>
        </Fragment>
    );
};

export default SingleBioPage;