import React from 'react';

const Logo = () => {
    return (
            <div className='mamass-loader-body'>
                <span className='logoM'>M</span>
                <div className='mamass-primary-loader'>

                    <span style={{"--i":2}}>A</span>
                    <span style={{"--i":3}}>M</span>
                    <span style={{"--i":4}}>A</span>
                    <span style={{"--i":5}}>'</span>
                    <span style={{"--i":6}}>S</span>
                    {/* <span style={{"--i":7}} className='mamas-loader-dot-icon'>⬤</span>
                    <span style={{"--i":8}} className='mamas-loader-dot-icon'>⬤</span>
                    <span style={{"--i":9}} className='mamas-loader-dot-icon'>⬤</span> */}
                </div>
            </div>
    );
};

export default Logo;