import React, { Fragment, useRef } from 'react';
import Speacer from '../driver/Speacer';
import {  setEmail } from '../../helper/sessionHelper';
import { sentOTPRequest } from '../../API/APIUser';
import { IsEmail } from '../../helper/FormHelper';
import { RequiredMessage } from '../../helper/NotificationHelper';

const EmailVerifyForm = () => {
    


    let emailRef = useRef()

    const formSubmit = (event) => {
        event.preventDefault()
    }

    const sentOTP =async () => {
        const email = emailRef.value;
        if(IsEmail(email)){
            RequiredMessage('Enter your email')
        }else{
        const res = await sentOTPRequest(email)
        if(res === true){
            setEmail(email)
            window.location.href= '/newPassword'
        }
        }

    }

    return (
        <Fragment>
            <div className="container" style={{height: '85vh'}}>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-7 col-12">
                        <form onSubmit={formSubmit} className='mamas-login-form-area center-midle' style={{height: '85vh'}}>
                            <div className="login-container" style={{ boxShadow: 'var(--shadow1)', padding: '20px 15px'}}>
                                <div className="login-box " style={{background: '#0f0f0f !important'}}>
                                <div className="login-header">
                                    <i className="login-icon"></i>
                                </div>
                                <h2 className='title1 ' style={{fontSize: '20px', lineHeight: '30px', textAlign: 'center'}}>Email Verify</h2>
                                <p className="text" >Enter your existing email what that Involved</p>
                                
                                <div className=" center-midle" >

                                    <input ref={(input)=> emailRef=input} type='email' className='form-control' placeholder='Emaill address' />
                                    <Speacer Space={20} />
                                    <button onClick={sentOTP} className="btn tr3" style={{background: 'var(--nav-bg)', letterSpacing: '1px', color: 'var(--color)'}}>Go ahead</button>
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EmailVerifyForm;