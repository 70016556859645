import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { OTPVerifyRequest, signupRequest } from '../../API/APIUser';
import { setEmail } from '../../helper/sessionHelper';
import { RequiredMessage, SuccessMessage } from '../../helper/NotificationHelper';
import { IsEmail, IsEmpty } from '../../helper/FormHelper';

const SignupForm = () => {

    const [Username, setUsername] = useState("")
    const [showPass, setShowPass] = useState("bi-eye-slash")
    const [Result, setResult] = useState(false)
    let emailRef, usernameRef, passWordRef = useRef()

    const formSubmit = (e) => {
        e.preventDefault()
    }

    const makeUserName = () => {
        const username = usernameRef.value;
        setUsername(username.toLowerCase().replace(' ', '-'))
        // alert(username.toLowerCase().replace(' ', '-'))
    }

    const ShowPassword = () => {
        setShowPass('bi-eye')
        setResult(true)
        if(Result === true){
            setResult(false)
            setShowPass('bi-eye-slash')
        }
    }

    const signUP =async () => {



        const email = emailRef.value;
        const password = passWordRef.value;
        const PostBody = {email: email, username: Username, FName: "", LName: "", Gender: "", Location: "", DateOfBirth: "", Password: password}
        if(IsEmail(email)){
            RequiredMessage('Enter valid email')
        }else if(IsEmpty(Username)){
            RequiredMessage('Enter unique username')
        }else if(IsEmpty(password)){
            RequiredMessage('Must use strong password')
        }else{
            const res = await signupRequest(PostBody)
            if(res === 'coppyEmail'){
                RequiredMessage('Email & username should be unique')
            }else if(res === true){
                await OTPVerifyRequest(email)
                setEmail(email)
                window.location.href = '/approve?=user'

            }else{
                RequiredMessage('Try again later')
            }
        }
    }


    return (
        <form onSubmit={formSubmit} className='mamas-login-form-area'>
            <div className="mamas-login-content">

                <h2 className='title1'>SIGN UP</h2>
                <p className='text'>Sign up quickly and easily! Before entering your email, please make sure it's valid. Providing an incorrect or invalid email will result in restrictions on your account.</p>

            </div>

            <div className="mamas-login-fields">
                <div className='d-flex' style={{gap: '10px'}}>
                    <div>
                        <label htmlFor="email">Email*</label>
                        <input ref={(input)=>emailRef=input}  className='form-control ' type="email" id='email' placeholder='Enter email' />
                    </div>
                    <div>
                        <label htmlFor="text">{Username === "" ? "Username*" : Username}</label>
                        <input ref={(input)=>usernameRef=input} onKeyUp={makeUserName} className='form-control ' type="text" id='text'/>
                    </div>
                </div>
                <div>
                    <label htmlFor="password ">Password*</label>
                    <div style={{position: 'relative'}}>
                        <input ref={(input)=>passWordRef=input} className='form-control' type={Result === false ? 'password' : 'text'} id='password' placeholder='Enter password' />
                        <div className="show-pass">
                            <i onClick={ShowPassword} className={`bi ${showPass}`}></i>
                            {/* <i class="bi bi-eye"></i> */}
                        </div>
                    </div>
                </div>

            </div>

            <div className='mamas-login-content-ass'>
                <input type="checkbox" id='checkbox' required={true} style={{position: 'relative', top: '-12px'}}/>
                <label htmlFor="checkbox" className='text'>Yes, I Agree and Want to Create My Account. And I agree to accept all of Mama's terms & policies.</label>
                
            </div>

            <div>
                <button onClick={signUP} className='btn login-regist' style={{background: 'var(--nav-bg)', letterSpacing: '1px', color: 'var(--color)'}}>Sign up</button>
                <span className="or">Or sign in</span>
            </div>

            <div className='login-to-sign-btn'>
                Already have an account? <Link to='/login'>Login</Link>
            </div>

        </form>
    );
};

export default SignupForm;