import axios from "axios";
import { HideLoader2, ShowLoader2 } from "../redux/state-slices/settings-slice"
import store from "../redux/store/store"
import { BaseURL } from "../helper/Config";
import { RequiredMessage } from "../helper/NotificationHelper";


export async function UserLoginRequest(PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+'/UserLogin';
        const res = await axios.post(URL, PostBody)
        store.dispatch(HideLoader2())
        if(res.status === 200){
            if(res?.data.message === "Email verification required"){
                window.location.href = '/verify-email'
            }else{
                // console.log(res?.data)
                return res?.data
            }
        }else{
            return false
        }

    }catch(err){
        store.dispatch(HideLoader2())
        return false

    }
}

export async function signupRequest(PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/signup"
        const res = await axios.post(URL, PostBody)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else if(res.status === 200 && res.data.status === 'failed'){
             return 'coppyEmail'
        }
        else{
            console.log('failed')
        }


    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function OTPVerifyRequest(email) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/sendOTP/"+email
        const res = await axios.post(URL)
        store.dispatch(HideLoader2())
        if(res.status === 200 & res.data.status === 'status'){
            return true
        }else{
            return false
        }
        
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function ApprovalRequest(email, OTP) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/accountApproval/"+email+"/"+OTP
        const res = await axios.post(URL)
        store.dispatch(HideLoader2())
        if(res.status === 200 & res.data.status === 'success'){
            return true
        }else{
            return false
        }
        
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function sentOTPRequest(email) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/sendOTP/"+email
        const res = await axios.post(URL)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res?.data?.data === '0'){
            RequiredMessage('Email is not match')
        }else if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function resetPasswordRequest(email, OTP, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/resetPassword/"+email+"/"+OTP
        const res = await axios.post(URL, PostBody)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}