import React, { useRef, useState } from 'react';
import Logo from '../../MasterLayout/navigation/Logo';
import { Link } from 'react-router-dom';
import { UserLoginRequest } from '../../API/APIUser';
import { setExpiration, setToken, setUserDetails } from '../../helper/sessionHelper';
import { RequiredMessage } from '../../helper/NotificationHelper';

const LoginForm = () => {

    let emailRef, passwordRef = useRef()

    const [type, setType] = useState(false)

    const formSubmit = (e) => {
        e.preventDefault()
    }

    const ShowPassword = () => {
        setType(true)
        if(type === true){
            setType(false)
        }
    }

    const Login = async () => {
        const email = emailRef.value;
        const password = passwordRef.value;
        const PostBody = {email: email, Password: password}
        const login = await UserLoginRequest(PostBody)
        if(login.status === 'success'){
            const expiration = new Date().getTime() + 48 * 60 * 60 * 1000

            setExpiration(expiration)
            setUserDetails(JSON.stringify(login.UserDetails))
            setToken(login.token)
            window.location.href = '/'
        }else{
            RequiredMessage('Try again later')
        }
    }

    return (
        <form onSubmit={formSubmit} className='mamas-login-form-area'>
            <Logo/>
            <div className="mamas-login-content">
                <h2>Greetings at MAMA'S</h2>
                <p>Login with your email & password. 'make sure you'll be honest with us'</p>
            </div>

            <div className="mamas-login-fields">
                <div>
                    <label htmlFor="email">Email*</label>
                    <input ref={(input)=> emailRef=input} className='form-control ' type="email" id='email' placeholder='Enter email' />
                </div>
                <div>
                    <label htmlFor="password ">Password*</label>
                    <input ref={(input)=> passwordRef=input} className='form-control' type={type === false ? "password" : "text"} id='password' placeholder='Enter password' />
                </div>

            </div>

            <div className='mamas-login-content-ass'>
                <div>
                    <input onClick={ShowPassword} type="checkbox" id='ShowPass'/>
                    <label htmlFor='ShowPass'>Show password</label>
                </div>
                <Link to='/verifymail' className='tr2 '>Reset password</Link>
            </div>

            <div>
                <button onClick={Login} className='btn login-regist text-light'>Login</button>
                <span className="or">Or sign up</span>
            </div>

            <div className='login-to-sign-btn'>
                Don't have an account? <Link to='/signup'>Signup now</Link>
            </div>

        </form>
    );
};

export default LoginForm;