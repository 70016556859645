import axios from "axios"
import { BaseURL } from "../helper/Config"
import { HideLoader2, ShowLoader2 } from "../redux/state-slices/settings-slice"
import store from "../redux/store/store"
import { getToken } from "../helper/sessionHelper"
import { RequiredMessage } from "../helper/NotificationHelper"
import { setBioDetail, setList } from "../redux/state-slices/bio-slice"
const AxiosToken = {headers: {'token': getToken()}}


export async function BioPostRequest(PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/createBios"
        const res = await axios.post(URL,PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return res
        }else{
            return false
        }

    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function BioListRequest(UserID) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/BioList/"+UserID
        const res = await axios.get(URL, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            // console.log(res?.data?.data)
            store.dispatch(setList(res?.data?.data))
        }else{
            return false
        }

    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function BioPhoto(Username, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/updateBios/"+Username
        const res = await axios.post(URL, PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function BioUpdate(Username, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/updateBios/"+Username
        const res = await axios.post(URL, PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function BioDetails(BioID) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/BiosDetails/"+BioID
        const res = await axios.get(URL)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            // console.log(res?.data?.data)
            store.dispatch(setBioDetail(res.data.data))
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}