import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetSubscriptionList } from '../../API/APISubscription';
import { useSelector } from 'react-redux';
import { IsActiveOrder } from '../../API/APIOrder';

const MamasPackages = () => {

    const [IsActive, setIsActive] = useState()

    useEffect(() => {
        (async() => {
            await GetSubscriptionList()
            const data = await IsActiveOrder()
            setIsActive(data)
        })()
    }, [])

    const SubList = useSelector((state) => (state.subscription.SubList))


    return (
        <div className='row subscriptions-grids d-flex'>
        {
            SubList.map((item, index) => 
                <div key={index.toString()} className="col-lg-6 col-md-6 col-12">
                    <div  className='subscription card tr3'>
                        <div className="subscription-header d-flex align-items-center">
                            <h1>{index+1} Package</h1>
                            <p className='price'>{item?.['Price']} <span>TK</span></p>
                        </div>
                        <h2 className='title1'>{item?.['Name']}</h2>
                        <div style={{width: '100%', borderBottom: '1px solid var(--widgetBorder1)' , padding: '5px 0px'}}></div>

                        <p className='text'>{item['Description'].length > 85 ? item['Description'].substring(0, 85) : item['Description']}</p>
                        <div>
                            <h4>Features:</h4>
                            <ul className='d-flex' style={{gap: '10px'}}>
                                {
                                    item?.['Features'].split(', ').map((list, index) => (
                                        <li key={index.toString()}>{list}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        {
                            IsActive?.data === true ? <Link to='/dashboard' className='tr3'>Workshope</Link> : IsActive?.data?.status === 'Waiting' ? <Link className='tr3'>Waiting for active</Link> : <Link to={`package-details?id=${item?.['_id']}`} className='tr3'>Purchase</Link>

                        }
                    </div>
                </div>
            )
        }
        </div>
    );
};

export default MamasPackages;