
class sessionHelper {

    setToken(token){
        localStorage.setItem('token', token)
    }
    getToken(){
        return localStorage.getItem('token')
    }
    setUserDetails(userdetails){
        localStorage.setItem('userdetails', userdetails)
    }
    getUserDetails(){
        return localStorage.getItem('userdetails')
    }
    setExpiration(expiration){
        localStorage.setItem('expiration', expiration)
    }
    getExpiration(){
        return localStorage.getItem('expiration')
    }

    setEmail(email){
        localStorage.setItem('email', email)
    }
    getEmail(){
        return localStorage.getItem('email')
    }



}

export const { setToken,  getToken, setUserDetails, getUserDetails, setExpiration, getExpiration, GetAuthDetails, setEmail, getEmail} = new sessionHelper()