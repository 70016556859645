import React, { Fragment, Suspense } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import MamasPackages from '../../components/users/MamasPackages';
import HomeWidget from '../../components/home/HomeWidget';

const UserHome = () => {



    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12">
                                <MamasPackages/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <HomeWidget/>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default UserHome;