import React from 'react';

const Footer = () => {
    return (
        <div style={{background: 'var(--foterBG1)', display: 'flex', alignItems: 'center', height: '50px'}}>
                  <div className='container' style={{height: '50px'}}>
                    <div className="row">
                        <div className="col-12">
                            <p style={{textAlign: 'center', lineHeight: '50px', color: 'var(--default)'}}>Alright resorved <strong>©MAMA'S</strong> since 2024</p>
                        </div>
                    </div>
                </div>  
        </div>

    );
};

export default Footer;