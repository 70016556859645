import axios from "axios"
import { BaseURL } from "../helper/Config"
import { HideLoader2, ShowLoader2 } from "../redux/state-slices/settings-slice"
import store from "../redux/store/store"
import { getToken } from "../helper/sessionHelper"
import { RequiredMessage } from "../helper/NotificationHelper"
import { setBioDetail, setList } from "../redux/state-slices/bio-slice"
const AxiosToken = {headers: {'token': getToken()}}


export async function BiosThemeUpdate(ThemeID, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/BiosThemeUpdate/"+ThemeID
        const res = await axios.post(URL, PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }

    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}

export async function BiosButtonUpdate(BUTTNID, PostBody) {
    try{
        store.dispatch(ShowLoader2())
        const URL = BaseURL+"/BiosButtonUpdate/"+BUTTNID
        const res = await axios.post(URL, PostBody, AxiosToken)
        store.dispatch(HideLoader2())
        if(res.status === 200 && res.data.status === 'success'){
            return true
        }else{
            return false
        }
    }catch(err){
        store.dispatch(HideLoader2())
        return false
    }
}