import React, { Fragment, useRef, useState } from 'react';
import Speacer from '../driver/Speacer';
import { Link } from 'react-router-dom';
import { RequiredMessage, SuccessMessage } from '../../helper/NotificationHelper';
import banner from '../../assets/images/mamas-baner.jpg'


import parse from 'html-react-parser';
import { BioPostRequest } from '../../API/APIBio';

const CreateNewBio = () => {
    let socialLinkRef, buttonParentRef, BtnToolsRef, DisplayNameRef, mainTitleRef, WhatsAppRef, TelegramRef, LinkedinRef, FacebookRef, InstagramRef = useRef()

    const [BTNImg, setBTNImg] = useState(banner)
    const [BTNLabel, setBTNLabel] = useState('Furnitures e.g chair, table')
    const [BTNLink, setBTNLink] = useState('')


    // button label
    const [Label1, setLabel1] = useState('')
    const [Label2, setLabel2] = useState('')
    const [Label3, setLabel3] = useState('')
    const [Label4, setLabel4] = useState('')
    const [Label5, setLabel5] = useState('')

    // button link
    const [Link1, setLink1] = useState('')
    const [Link2, setLink2] = useState('')
    const [Link3, setLink3] = useState('')
    const [Link4, setLink4] = useState('')
    const [Link5, setLink5] = useState('')

    // button link
    const [Icon1, setIcon1] = useState('<i class="bi bi-whatsapp"></i>')
    const [Icon2, setIcon2] = useState('<i class="bi bi-telegram"></i>')
    const [Icon3, setIcon3] = useState('<i class="bi bi-linkedin"></i>')
    const [Icon4, setIcon4] = useState('<i class="bi bi-facebook"></i>')
    const [Icon5, setIcon5] = useState('<i class="bi bi-instagram"></i>')

    const [Button, setButton] = useState([])

    const FormSubmit = (event) => {
        event.preventDefault()
    }

    const AddNewButton = () => {

        if(Label1 === "" && Link1 === ""){
            setLabel1(BTNLabel)
            setLink1(BTNLink)
        }else if(Label2 === "" && Link2 === ""){
            setLabel2(BTNLabel)
            setLink2(BTNLink)
        }else if(Label3 === "" && Link3 === ""){
            setLabel3(BTNLabel)
            setLink3(BTNLink)
        }else if(Label4 === "" && Link4 === ""){
            setLabel4(BTNLabel)
            setLink4(BTNLink)
        }else if(Label5 === "" && Link5 === ""){
            setLabel5(BTNLabel)
            setLink5(BTNLink)
        }else{
            console.log('You\'ve already created 5 button')
        }
        const BtnTools = BtnToolsRef;
        BtnTools.classList.remove('visiblity')

        const child = (
            <Link to={BTNLink} className='mamas-bio-button tr3' style={{borderColor: '#52288491', borderRadius: '5px', background: '#f4f4f9'}}>
                <img src={BTNImg} alt="bio"/>
                <span style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}>{BTNLabel}</span>
                <i className="bi bi-hand-index-thumb" style={{fontSize: '14px', color: 'rgb(103, 116, 142)'}}></i>
            </Link>
        )
        if(Button.length >= 5){
            RequiredMessage('You already have 5 buttons')
            return;
        }
        setButton((prevChildren) => [...prevChildren, child])
    }



    const OpenButtonTools = () => {



        // const states = [setLabel2, setLabel3, setLabel4, setLabel5];
        // if (currentIndex <= states.length) {
        //     // console.log(states[currentIndex]);
        //     setSTATE(states[currentIndex]);
        //     setCurrentIndex(currentIndex + 1);
        //   } else {
        //     console.log('All items executed');
        //   }


        const BtnTools = BtnToolsRef;
        if(BtnTools.classList.contains('bio-button-submit')){
            BtnTools.classList.toggle('visiblity')
        }
    }


    const OpenSocialLinks = () => {
        const social = socialLinkRef;
        if(social.classList.contains('bio-social-popup')){
            social.classList.toggle('bio-social-popup')
        }
    }
    const SocialUpdate = () => {
        alert('okay')
    }
    const LeaveNow = () => {
        const social = socialLinkRef;
        const BtnTools = BtnToolsRef;

        social.classList.add('bio-social-popup')
        BtnTools.classList.remove('visiblity')

    }


    const PublishNow = async () => {
        const DisplayName = DisplayNameRef.value;
        const MainTitle = mainTitleRef.value;
        const WhatsApp = WhatsAppRef.value;
        const Telegram = TelegramRef.value;
        const Linkedin = LinkedinRef.value;
        const Facebook = FacebookRef.value;
        const Instagram = InstagramRef.value;


        const PostBody = {
            DisplayName: DisplayName,
            MainTitle: MainTitle,
            Buttons: [
              { label: Label1, link: Link1 },
              { label: Label2, link: Link2 },
              { label: Label3, link: Link3 },
              { label: Label4, link: Link4 },
              { label: Label5, link: Link5 }
            ],
            SocialLinks: {
              WhatsApp: { icon: Icon1, link: WhatsApp },
              Telegram: { icon: Icon2, link: Telegram },
              Linkedin: { icon: Icon3, link: Linkedin },
              Facebook: { icon: Icon4, link: Facebook },
              Instagram: { icon: Icon5, link: Instagram }
            },
            PhoneNumber: '',
            PersonalEmail: '',
            ProfileLImg: ''
          };
          const post = await BioPostRequest(PostBody)
          if(post.data.message === 'Already you\'ve created two profiles'){
            RequiredMessage(post.data.message)
          }else if(post.data.message === 'Profile has been created'){
            SuccessMessage('Bio has been created')
          }else{
            RequiredMessage('Try again later')
          }
    }

    return (
        <Fragment>
            <div>


                <form onSubmit={FormSubmit} className=' my-5 '>
                    
                    {/* display name */}
                    <div  className='bio-user-name center-midle'> 
                        <input ref={(input) => DisplayNameRef=input} type="text" placeholder='Bio name' id='bioName'/>
                        <Speacer Space={5}/>
                        <label htmlFor="bioName" className='text-center'>Choose a creative bio name, keep it short and unique because 'less is more'. Also, avoid copying names.</label>
                    </div>
                    <Speacer Space={100}/>
                    {/* Main title */}
                    <div  className='bio-user-bio center-midle'>
                        <textarea ref={(textarea) => mainTitleRef=textarea} name="" id="mainTitle" placeholder='Appear a unique tile'></textarea>
                        <label htmlFor="mainTitle" className='text-center'>Write a unique title, keep it short and unique because 'less is more'. Also, avoid copying names.</label>
                    </div>
                    <Speacer Space={100}/>

                    <div>
                        {/* buttons will appear */}
                        <div className='mamas-bios-buttons center-midle tr3'>
                            {Button}
                        </div>

                        {/* Open tools & add new buttons */}
                        <div className='add-bio-buttons center-midle'>
                            <p className='title1 tr3'>Add new button</p>
                            <span onClick={OpenButtonTools} className='tr3'><i class="bi bi-plus"></i></span>

                            <div ref={(div)=>BtnToolsRef=div} className='bio-button-submit center-midle pt-2'>
                                <div className='label-up'>
                                    <div className='up'>
                                        <i class="bi bi-cloud-plus"></i>
                                        <input type="file" />
                                    </div>
                                    <div className='label'>

                                        <input onChange={(e)=>setBTNLabel(e.target.value)} type="text" placeholder='Buy now 40% of'/>
                                    </div>
                                </div>
                                <div className='label-up'>
                                    <div className='up'>
                                        <i class="bi bi-link-45deg"></i>
                                        <input type="file" />
                                    </div>
                                    <div className='label'>
                                        <input onChange={(e)=>setBTNLink(e.target.value)} type="text" placeholder='https://mamas.com'/>
                                    </div>
                                </div>
                                
                                <div className='d-flex justify-content-between py-1' style={{width: '100%'}}>

                                    <button onClick={AddNewButton} className='btn social-save-leave-btn'>Insert</button>
                                    <button onClick={LeaveNow} className='btn social-save-leave-btn'>Leave now</button>
                                </div>
                            </div>

                        </div>
                    </div>



                    <Speacer Space={150}/>
                    <div className='tr3' style={{position: 'relative'}}>
                        <div className='add-bio-buttons center-midle'>
                            <p className='title1 tr3'>Add social link</p>
                            <span onClick={OpenSocialLinks} className='tr3'><i class="bi bi-plus"></i></span>
                        </div>
                        <ul ref={(ul)=>socialLinkRef=ul} className='bio-social-links bio-social-popup tr3'>

                            <li>
                                <label htmlFor="whatsApp">{Icon1}</label>
                                <input ref={(input) => WhatsAppRef=input} type="text" id='whatsApp'/>
                            </li>
                            <li>
                                <label htmlFor="telegram">{Icon2}</label>
                                <input ref={(input) => TelegramRef=input} type="text" id='telegram'/>
                            </li>
                            <li>
                                <label htmlFor="linkedin">{Icon3}</label>
                                <input ref={(input) => LinkedinRef=input} type="text" id='linkedin'/>
                            </li>
                            <li>
                                <label htmlFor="facebook">{Icon4}</label>
                                <input ref={(input) => FacebookRef=input} type="text" id='facebook'/>
                            </li>
                            <li>
                                <label htmlFor="instagram">{Icon5}</label>
                                <input ref={(input) => InstagramRef=input} type="text" id='instagram'/>
                            </li>
                            <Speacer Space={5}/>
                            <li className='d-flex justify-content-between'>
                                <button onClick={SocialUpdate} className='btn social-save-leave-btn'>Save now</button>
                                <button onClick={LeaveNow} className='btn social-save-leave-btn'>Leave now</button>
                            </li>
                        </ul>
                    </div>
                    <Speacer Space={150}/>
                    <div className='center-midle'>
                        <button onClick={PublishNow} className='btn w-50 btn-1-0-0' style={{background: 'var(--nav-bg)', color: 'var(--color)', letterSpacing: '1px'}}>Reach out to the client</button>
                    </div>
                </form>
            </div>
            <Speacer Space={50}/>
        </Fragment>
    );
};

export default CreateNewBio;