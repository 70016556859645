import React, { Fragment, Suspense } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import DashbaordWidget from '../../components/dashboard/DashbaordWidget';
import CreateNewBio from '../../components/dashboard/CreateNewBio';
import BioCreateButton from '../../components/dashboard/BioCreateButton';
import BioCard from '../../components/dashboard/BioCard';
import Speacer from '../../components/driver/Speacer';

const Dashboard = () => {

    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-12">
                                <BioCard/>
                                <Speacer Space={55} />
                                <CreateNewBio/>
                                <BioCreateButton/>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12">
                                <DashbaordWidget/>
                            </div>
                        </div>
                    </div>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default Dashboard;