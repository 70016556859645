import React, { Fragment, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import thumb from '../../assets/images/user.jpg'
import { Link } from 'react-router-dom';
import { BioListRequest } from '../../API/APIBio';
import { getUserDetails } from '../../helper/sessionHelper';
import { useSelector } from 'react-redux';

const BioCard = () => {

    useEffect(() => {
        const UserDetails = JSON.parse(getUserDetails());
        const UserID = UserDetails.user_id;

        ( async() => {
            await BioListRequest(UserID)
        })()
        
    }, [])

    const BioList = useSelector((state) => (state.bio.List))
    console.log(BioList?.[0]?.['UserName'])

    return (
        <Fragment>
            <div className="row">
                {
                    BioList?.map((item, index) => 
                        <div key={index.toString()} className="col-lg-6 col-md-6 col-12">
                            <Card className='subscription' style={{ width: '100%' }}>
                                <Card.Img className='bio-grid-img tr3' variant="top" src={item?.['ProfileLImg'] === "" ? thumb : item?.['ProfileLImg']}/>
                                <Card.Body>
                                    <Card.Title className='title1'>{item?.['DisplayName']}</Card.Title>
                                    <Card.Text className='text'>
                                    {item?.['MainTitle']}
                                    </Card.Text>
                                    <div className='d-flex justify-content-between bio-card-btn'> 
                                        <Link to={`/update-bio?user=${item?.['UserName']}`}  className='tr3'>Customize <i className="bi bi-pencil-square"></i></Link>
                                        <Link to={`/bio?user=${item?.['UserName']}`}  className='tr3'>Vew as <i className="bi bi-eye"></i></Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                }

            </div>
        </Fragment>
    );
};

export default BioCard;