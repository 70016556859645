import React, { Fragment, useRef, useState } from 'react';
import { IsEmpty } from '../../helper/FormHelper';
import Speacer from '../driver/Speacer';
import { RequiredMessage } from '../../helper/NotificationHelper';
import { resetPasswordRequest, sentOTPRequest } from '../../API/APIUser';
import { getEmail } from '../../helper/sessionHelper';
import ReactCodeInput from 'react-code-input';

const NewPasswordForm = () => {

    let  passowrdRef = useRef()
    const [type, setType] = useState(false)
    const [OTP, setOTP] = useState()

    const formSubmit = (event) => {
        event.preventDefault()
    }
    const ShowPassword = () => {
        setType(true)
        if(type === true){
            setType(false)
        }
    }

    const sentOTP =async () => {
        const passowrd = passowrdRef.value;
        const PostBody = {Password: passowrd}
        const email = getEmail()
        if(IsEmpty(passowrd)){
            RequiredMessage('Enter strong password')
        }else if(IsEmpty(OTP)){
            RequiredMessage('Enter OTP')
        }
        else{
            RequiredMessage(passowrd)

            const res = await resetPasswordRequest(email, OTP, PostBody)
            if(res === true){
                localStorage.removeItem('email')
                window.location.href= '/login'
            }
        }

    }

    let  defaultInputStyle= {
        fontFamily: "monospace",
        MozAppearance: "textfield",
        margin: "4px",
        paddingLeft: "0px",
        width: "35px",
        borderRadius: '3px',
        height: "35px",
        fontSize: "22px",
        border: '1px solid #b5a2d7',
        boxSizing: "border-box",
        color: "rgba(0, 0, 0, 0.84)",
        backgroundColor: "transparent",
        borderColor: "lightgrey",
        textAlign: 'center',
        lineHeight: '35px'
    }

    return (
        <Fragment>
            <div className="container" style={{height: '85vh'}}>
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-7 col-12">
                        <form onSubmit={formSubmit} className='mamas-login-form-area center-midle' style={{height: '85vh'}}>
                            <div className="login-container" style={{ boxShadow: 'var(--shadow1)', padding: '20px 15px'}}>
                                <div className="login-box " style={{background: '#0f0f0f !important'}}>
                                <div className="login-header">
                                    <i className="login-icon"></i>
                                </div>
                                <h2 className='title1 ' style={{fontSize: '20px', lineHeight: '30px', textAlign: 'center'}}>New Password</h2>
                                <p className="text" >You should must enter strong password</p>
                                
                                <div className=" center-midle" >

                                    <ReactCodeInput onChange={(value) => setOTP(value)} inputStyle={defaultInputStyle} className=''  fields={6}/>
                                    <Speacer Space={10} />
                                
                                    <input ref={(input)=> passowrdRef=input} type={type === true ? 'text' : 'password'} className='form-control' placeholder='Password' />
                                    <div style={{textAlign: 'left', width: '100%' }}>
                                        <input onClick={ShowPassword} type="checkbox" id='ShowPass'/>
                                        <label htmlFor='ShowPass'>Show password</label>
                                    </div>
                                    <Speacer Space={20} />
                                    <button onClick={sentOTP} className="btn tr3" style={{background: 'var(--nav-bg)', letterSpacing: '1px', color: 'var(--color)'}}>Set it as password</button>
                                </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default NewPasswordForm;