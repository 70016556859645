import React, { Fragment, Suspense } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import MamasLoader2 from '../../MasterLayout/loading/MamasLoader2';
import LoginForm from '../../components/forms/LoginForm';


const LoginPage = () => {

    return (
        <Fragment>
            <ParentLayout>
                <Suspense fallback={<MamasLoader2/>}>
                    <div className="container mamas-mob-vh" style={{height: '85vh', width: '100%'}}>
                            <div className="row xs-vh" style={{width: '100%' ,height: '75vh', margin: '50px auto', boxShadow: 'var(--shadow1)', background: 'var(--body)', borderRadius: '12px'}}>


                                <div className="col-lg-6 col-md-6 col-12 mamas-login-caption" style={{boxShadow: 'var(--shadow1)'}}>
                                    <LoginForm/>
                                </div>


                                <div className="col-lg-6 col-md-6 col-12 mamas-login-template-theme">
                                </div>

                            </div>

                            <MamasLoader2/>
                    </div>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default LoginPage;