import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store/store';
import ReactDOM from 'react-dom/client';
import App from './App';
import '../src/assets/styles/bootstrap.css';
import '../src/assets/styles/animate.min.css';
import '../src/assets/styles/main.css';
import '../src/assets/styles/screen.css';
import '../src/assets/styles/navigation.css';
import '../src/assets/styles/responsive.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.css';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Provider store={store}>
      <App />
    </Provider>


  </React.StrictMode>
);

