import React, { Fragment, Suspense } from 'react';
import ParentLayout from '../../MasterLayout/navigation/MasterLayout';
import NewPasswordForm from '../../components/forms/NewPasswordForm';

const NewPasswordPage = () => {
    return (
        <Fragment>
            <ParentLayout>
                <Suspense>
                    <NewPasswordForm/>
                </Suspense>
            </ParentLayout>
        </Fragment>
    );
};

export default NewPasswordPage;